import React from "react";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { QRCode } from "@/shared/components/QRCode";
import { CopyAddressButton } from "@/shared/components/CopyAddressButton";
import { useToggler } from "@/shared/hooks/useToggler";
import { MoneySentPopup } from "@/pages/SubscriptionPage/components/ManeySentPopup";
export var AddFunds = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var subscriptions = useStoreContext(SubscriptionStore);
    var moneySentPopup = useToggler();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center", marginTop: "48px" },
            React.createElement(BaseIcon, { icon: "Back", size: 24, style: {
                    cursor: "pointer",
                }, onClick: subscriptions.prevStep }),
            React.createElement(Typography, { textAlign: "center", type: "text28Bold" },
                "Receive ", (_a = subscriptions.selectedCurrency) === null || _a === void 0 ? void 0 :
                _a.currency.shortName)),
        React.createElement(Box, { padding: "32px", bgColor: "general-white", borderRadius: "32px", marginTop: "32px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "24px" },
                React.createElement(Typography, { type: "text24", sx: { display: "flex", alignItems: "center" }, gap: "8px" },
                    "Your",
                    React.createElement(TokenIcon, { symbol: (_b = subscriptions.selectedCurrency) === null || _b === void 0 ? void 0 : _b.currency.slug, size: 24 }), (_c = subscriptions.selectedCurrency) === null || _c === void 0 ? void 0 :
                    _c.currency.description,
                    " ",
                    "address"),
                ((_d = subscriptions.selectedCurrency) === null || _d === void 0 ? void 0 : _d.address) && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" },
                    React.createElement(QRCode, { value: (_e = subscriptions.selectedCurrency) === null || _e === void 0 ? void 0 : _e.address, size: 160 }),
                    React.createElement(CopyAddressButton, { address: (_f = subscriptions.selectedCurrency) === null || _f === void 0 ? void 0 : _f.address }))),
                React.createElement(Box, { padding: "24px", bgColor: "black-50", width: "100%", borderRadius: "16px", boxSizing: "border-box" },
                    React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
                        React.createElement(Typography, { type: "text14", sx: {
                                color: "#80889C",
                            } }, "Network"),
                        React.createElement(Typography, { type: "text16" }, (_g = subscriptions.selectedCurrency) === null || _g === void 0 ? void 0 : _g.blockchain.name)),
                    React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" },
                        React.createElement(Typography, { type: "text14", sx: {
                                color: "#80889C",
                            } }, "Minimum"),
                        React.createElement(Typography, { type: "text16" }, "***"))),
                React.createElement(Box, { padding: "24px", bgColor: "black-50", width: "100%", borderRadius: "16px", boxSizing: "border-box" },
                    React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
                        React.createElement(BaseIcon, { icon: "WarningOrange", size: 44 }),
                        React.createElement(Typography, { color: "black-500", type: "text14" },
                            "Send only",
                            " ", (_h = subscriptions.selectedCurrency) === null || _h === void 0 ? void 0 :
                            _h.currency.shortName,
                            " ",
                            "to this address. Sending other assets will result in permanent loss. If in doubt, receive a small amount first"))))),
        React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "32px" },
            React.createElement(Button, { size: "l", sx: { width: "280px" }, onClick: moneySentPopup.on }, "Money was sent")),
        moneySentPopup.enabled && React.createElement(MoneySentPopup, null)));
};
