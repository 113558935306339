import { observer } from "mobx-react-lite";
import * as React from "react";
import { Outlet } from "react-router-dom";
import styles from "./index.module.scss";
import { Welcome } from "@/pages/AuthPage/components/Welcome";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { Overlay } from "@/shared/components/Overlay";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore } from "@/shared/hooks/useStore";
export var AuthPage = observer(function () {
    var page = useStore(AuthPageStore);
    var PageProvider = useProvider(AuthPageStore);
    var media = useMedia();
    return (React.createElement(PageProvider, { value: page },
        React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.main },
                React.createElement(Welcome, null)),
            media.desktop ? (React.createElement("div", { className: styles.side },
                React.createElement(Outlet, null))) : page.formVisible ? (React.createElement(Overlay, null,
                React.createElement("div", { className: styles.popup },
                    React.createElement(Outlet, null)))) : null)));
});
