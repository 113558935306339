import React from "react";
import { observer } from "mobx-react-lite";
import { CardList } from "@/pages/CardPage/components/CardList";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardListStore } from "@/shared/stores/CardListStore";
import { OrderCardPopup } from "@/pages/CardPage/components/OrderCardPopup";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { useToggler } from "@/shared/hooks/useToggler";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { VerificationBanner } from "@/entities/Verification";
import { useMedia } from "@/shared/hooks/useMedia";
export var CardPage = observer(function () {
    var popup = useToggler();
    var media = useMedia();
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var cardList = useStoreContext(CardListStore);
    var orderCard = useStoreContext(OrderCardStore);
    React.useEffect(function () {
        cardList.sync();
    }, [cardList]);
    var onClose = function () {
        orderCard.success = false;
    };
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
        React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
            React.createElement(Typography, { type: media.desktop ? "text32Bold" : "text24Bold" }, "Cards"),
            status === VerificationStatus.GREEN && (React.createElement(BaseIcon, { icon: "Plus", bgColor: "deep-blue-500", color: "general-white", size: 36, padding: "12px", borderRadius: "12px", onClick: popup.on }))),
        status === VerificationStatus.INIT && (React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr", gap: "20px" },
            React.createElement(VerificationBanner, null))),
        status === VerificationStatus.GREEN && (React.createElement(React.Fragment, null,
            React.createElement(CardList, { cards: cardList.list }),
            popup.enabled && React.createElement(OrderCardPopup, { onClose: popup.off }),
            orderCard.success && (React.createElement(SuccessPopup, { title: "Your order was successfully completed.\n" +
                    "The card will be with you soon.", onClose: onClose }))))));
});
