import { observer } from "mobx-react-lite";
import React from "react";
import { CreateAccountPopup } from "@/features/create-account/components/CreateAccountPopup";
import { Box, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { OpenAccountButton } from "@/pages/HomePage/components/Accounts/components/OpenAccountButton";
export var Accounts = observer(function () {
    var popup = useToggler();
    var balanceList = useStoreContext(BalanceListStore);
    var accounts = useStoreContext(AccountListStore);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.fiat.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.fiat]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { type: "text24" }, "Accounts"),
        React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "16px", marginTop: "16px" },
            React.createElement(OpenAccountButton, null)),
        popup.enabled && (React.createElement(CreateAccountPopup, { onClose: popup.off, onSuccess: function () {
                accounts.sync();
                popup.off();
            } }))));
});
