import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var NewCardButton = function () {
    return (React.createElement(Box, { padding: "22px 20px", bgColor: "general-white", borderRadius: "22px", display: "flex", alignItems: "center", gap: "8px", sx: {
            cursor: "pointer",
        } },
        React.createElement(Box, { width: "68px", height: "48px", bgColor: "black-100", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "6px" },
            React.createElement(BaseIcon, { icon: "Card", color: "deep-blue-500", size: 32 })),
        React.createElement(Typography, { type: "text16Bold", color: "deep-blue-500" }, "New card")));
};
