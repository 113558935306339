import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { FormControlInput, Box, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { useStoreContext } from "@/shared/hooks/useStore";
import QRCode from "react-qr-code";
import { MfaStore } from "@/shared/stores/MfaStore";
import { Loader } from "@/shared/components/Loader";
import { onChange } from "@/shared/utils/input";
import { AuthStore } from "@/shared/stores/AuthStore";
export var MFAForm = observer(function () {
    var _a;
    var mfa = useStoreContext(MfaStore);
    var auth = useStoreContext(AuthStore);
    React.useEffect(function () {
        mfa.checkIsOtpActive();
        if (!mfa.isActive && !mfa.barcodeUri) {
            mfa.associateMFA();
        }
    }, []);
    var submitCode = function () {
        auth.activateMfa();
    };
    return (React.createElement(AuthForm, { title: "MFA Registration", onSubmit: submitCode, content: React.createElement(React.Fragment, null,
            React.createElement(Box, null,
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text16Bold" }, "Step 1: Download an Authenticator App")),
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text12", color: "black-500" }, "Download an Authenticator App, such as Microsoft Authenticator or Google Authenticator, to your mobile device."))),
            React.createElement(Box, { marginTop: "16px" },
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text16Bold" }, "Step 2: Scan QR code with your Authenticator App")),
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text12", color: "black-500" }, "Scan the QR code below with the Authenticator App on your mobile device.")),
                React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", marginTop: "16px" }, !mfa.loading && mfa.barcodeUri ? (React.createElement(Box, null,
                    React.createElement(QRCode, { value: mfa.barcodeUri, size: 240 }))) : (React.createElement(Loader, { size: 40 })))),
            React.createElement(Box, { marginTop: "16px" },
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text16Bold" }, "Step 3: Verify Authenticator Code")),
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text12", color: "black-500" }, "After scanning the QR code, the Authenticator App will display a six-digit code for you to enter below.")),
                React.createElement(FormControlInput, { marginTop: "16px", type: "text", label: "Verification Code", disabled: mfa.loading, value: mfa.code, onChange: onChange(action(function (value) {
                        mfa.code = value;
                    })), error: (_a = mfa.errors[0]) === null || _a === void 0 ? void 0 : _a.description }))), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: mfa.loading }, "Continue") }));
});
