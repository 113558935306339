import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { DepositFiatInfo } from "@/pages/DepositPage/components/DepositFiat/Info";
import { Header } from "@/pages/DepositPage/components/Header";
import { DepositType, ROUTES } from "@/routes";
import { Typography } from "@/shared/components";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { DropdownInput } from "@/shared/components/DropdownInput";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
export var DepositFiat = observer(function () {
    var _a, _b, _c;
    var params = useParams();
    var popup = useToggler();
    var navigate = useNavigate();
    var accountList = useStoreContext(AccountListStore);
    var addressList = useStoreContext(AddressListStore);
    var account = params.id ? accountList.fiatById[params.id] : undefined;
    var address = account
        ? (_a = addressList.data[account === null || account === void 0 ? void 0 : account.account]) === null || _a === void 0 ? void 0 : _a[account === null || account === void 0 ? void 0 : account.currency]
        : undefined;
    var loading = params.id ? !address || !account : false;
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    return (React.createElement(React.Fragment, null,
        popup.enabled && (React.createElement(BankAccountPopup, { selectedId: account === null || account === void 0 ? void 0 : account.account, onClose: popup.off, onSelect: function (id) {
                popup.off();
                navigate(generatePath(ROUTES.deposit, {
                    id: id,
                    type: DepositType.Fiat,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { loading: loading, main: React.createElement(React.Fragment, null,
                React.createElement(Header, { active: DepositType.Fiat }),
                React.createElement(DropdownInput, { loading: loading, onClick: popup.on }, account && (React.createElement(React.Fragment, null,
                    React.createElement(BankIcon, { symbol: account.currency, size: 36 }),
                    React.createElement(Typography, { type: "text16" }, account.shortName),
                    React.createElement(Typography, { type: "text16" }, (_c = (_b = address === null || address === void 0 ? void 0 : address.first) === null || _b === void 0 ? void 0 : _b.accountNumber) !== null && _c !== void 0 ? _c : "\u200b"))))), card: account && React.createElement(DepositFiatInfo, null) })));
});
