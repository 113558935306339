import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useGetVerificationInfo } from "@/api/registration";
import { tokenStorage } from "@/api/tokenStorage";
import { CreateRecipientStore } from "@/features/create-recipient/store/CreateRecipientStore";
import { RootInner } from "@/shared/components/Root/Inner";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { BalanceTypeStore } from "@/shared/stores/BalanceTypeStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { EventsStore } from "@/shared/stores/EventsStore";
import { MenuStore } from "@/shared/stores/MenuStore";
import { MfaStore } from "@/shared/stores/MfaStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
import { SumSubStore } from "@/shared/stores/SumSubStore";
import { KYC0PageStore } from "@/pages/KYC0Page/store/KYC0PageStore";
import { CardListStore } from "@/shared/stores/CardListStore";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
export var Root = observer(function () {
    var _a;
    var addressList = useStore(AddressListStore);
    var currencyList = useStore(CurrencyListStore);
    var balanceList = useStore(BalanceListStore, currencyList);
    var cardList = useStore(CardListStore);
    var events = useStore(EventsStore);
    var twoFA = useStore(MfaStore);
    var auth = useStore(AuthStore, tokenStorage, twoFA);
    var menu = useStore(MenuStore);
    var createRecipient = useStore(CreateRecipientStore);
    var userCurrency = useStore(UserCurrencyStore);
    var balance = useStore(BalanceTypeStore);
    var sumSubVerification = useStore(SumSubStore);
    var KYC0 = useStore(KYC0PageStore);
    var orderCard = useStore(OrderCardStore);
    var AuthProvider = useProvider(AuthStore);
    var MenuProvider = useProvider(MenuStore);
    var UserCurrencyProvider = useProvider(UserCurrencyStore);
    var CurrencyListProvider = useProvider(CurrencyListStore);
    var CardListProvider = useProvider(CardListStore);
    var BalanceProvider = useProvider(BalanceTypeStore);
    var accountList = useStore(AccountListStore, auth, events);
    var AccountListProvider = useProvider(AccountListStore);
    var CreateRecipientProvider = useProvider(CreateRecipientStore);
    var recipientList = useStore(RecipientListStore, auth);
    var RecipientListProvider = useProvider(RecipientListStore);
    var TwoFAProvider = useProvider(MfaStore);
    var SumSubProvider = useProvider(SumSubStore);
    var EventsProvider = useProvider(EventsStore);
    var BalanceListProvider = useProvider(BalanceListStore);
    var AddressListProvider = useProvider(AddressListStore);
    var KYC0Provider = useProvider(KYC0PageStore);
    var OrderCardProvider = useProvider(OrderCardStore);
    var verification = useGetVerificationInfo(auth.authorized);
    var queryClient = useQueryClient();
    React.useEffect(function () { return function () {
        var _a;
        if (!((_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail))
            queryClient.clear();
    }; }, [(_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail, queryClient]);
    React.useEffect(function () {
        toggleLoader(auth.initialized &&
            accountList.initialized &&
            (auth.authorized ? verification.isFetched : auth.initialized));
    }, [
        auth.initialized,
        accountList.initialized,
        verification.isFetched,
        auth.authorized,
    ]);
    React.useEffect(function () {
        if (verification.isFetched && verification.data) {
            orderCard.setVerificationStatus(verification.data.status === "GREEN");
        }
    }, [verification.isFetched, verification.data, orderCard]);
    return (React.createElement(AddressListProvider, { value: addressList },
        React.createElement(BalanceListProvider, { value: balanceList },
            React.createElement(EventsProvider, { value: events },
                React.createElement(TwoFAProvider, { value: twoFA },
                    React.createElement(CreateRecipientProvider, { value: createRecipient },
                        React.createElement(RecipientListProvider, { value: recipientList },
                            React.createElement(AccountListProvider, { value: accountList },
                                React.createElement(BalanceProvider, { value: balance },
                                    React.createElement(CurrencyListProvider, { value: currencyList },
                                        React.createElement(CardListProvider, { value: cardList },
                                            React.createElement(UserCurrencyProvider, { value: userCurrency },
                                                React.createElement(MenuProvider, { value: menu },
                                                    React.createElement(AuthProvider, { value: auth },
                                                        React.createElement(SumSubProvider, { value: sumSubVerification },
                                                            React.createElement(KYC0Provider, { value: KYC0 },
                                                                React.createElement(OrderCardProvider, { value: orderCard },
                                                                    React.createElement(RootInner, null))))))))))))))))));
});
function toggleLoader(visible) {
    var _a;
    (_a = document.getElementById("loader")) === null || _a === void 0 ? void 0 : _a.classList.toggle("active", !visible);
}
