import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@/shared/components";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { dataToText, saveToPDF } from "@/shared/utils/save-to-pdf";
export var DepositFiatInfo = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var params = useParams();
    var media = useMedia();
    var accountList = useStoreContext(AccountListStore);
    var addressList = useStoreContext(AddressListStore);
    var account = params.id ? accountList.fiatById[params.id] : undefined;
    var address = account ? (_a = addressList.data[account === null || account === void 0 ? void 0 : account.account]) === null || _a === void 0 ? void 0 : _a[account === null || account === void 0 ? void 0 : account.currency] : undefined;
    var _k = React.useState(0), activeIndex = _k[0], setActiveIndex = _k[1];
    var activeAddress = address === null || address === void 0 ? void 0 : address.list.at(activeIndex);
    var info = [
        ["Beneficiary", (_b = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _b !== void 0 ? _b : "—"],
        ["Beneficiary address", (_c = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderAddress) !== null && _c !== void 0 ? _c : "—"],
        ["Bank account number", (_d = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _d !== void 0 ? _d : "—"],
        ["Account routing number", (_e = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountRoutingNumber) !== null && _e !== void 0 ? _e : "—"],
        ["Memo", (_f = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _f !== void 0 ? _f : "—"],
        ["Bic", (_g = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.swiftBic) !== null && _g !== void 0 ? _g : "—"],
        ["Bank name", (_h = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _h !== void 0 ? _h : "—"],
        ["Bank address", (_j = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankAddress) !== null && _j !== void 0 ? _j : "—"],
    ];
    var copy = useCopy(dataToText(info));
    var savePDF = function () {
        saveToPDF(dataToText(info, 60), "bank.pdf");
    };
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
        React.createElement(Box, { width: "100%", display: "grid", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr 1fr", gap: "10px" }, address === null || address === void 0 ? void 0 : address.list.map(function (item, index) {
            var _a;
            return (React.createElement(Button, { size: "xs", variant: activeIndex === index ? undefined : "Gray", onClick: function () {
                    setActiveIndex(index);
                } }, (_a = item.type) === null || _a === void 0 ? void 0 : _a.toUpperCase()));
        })),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", width: "100%" }, info.map(function (item, index) { return (
        // eslint-disable-next-line react/no-array-index-key
        React.createElement(Box, { display: "flex", flexDirection: "column", key: index },
            React.createElement(Typography, { type: "text14", color: "black-400" }, item[0]),
            React.createElement(Typography, { type: "text16", color: media.desktop ? "general-white" : undefined }, item[1]))); })),
        React.createElement(Box, { width: "100%", display: media.desktop ? "grid" : "flex", flexDirection: media.desktop ? undefined : "column", alignItems: media.desktop ? "center" : "stretch", gridAutoFlow: "column", gridAutoColumns: "minmax(0, 1fr)", gap: media.desktop ? "30px" : "10px", whiteSpace: "nowrap" },
            React.createElement(Button, { variant: "LightBlue", size: "s", onClick: copy }, "Copy details"),
            React.createElement(Button, { variant: "LightBlue", size: "s", onClick: savePDF }, "Save details in PDF"))));
});
